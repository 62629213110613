import {mapState,mapGetters,mapMutations} from "vuex"
import preset from "@/store/preset";
export default {
    computed:{
        ...mapState(['process','preset']),
        ...mapGetters('preset',['client_token']),
        ...mapGetters('process',['isCollapsed']),
        ...mapGetters('user',['user']),
        en(){
            return this.$english();
        },
        english(){
            return this.$english();
        },
        mobile() {
            return this.$isMobile();
        },
    },
    methods:{
        setLanguage() {
            this.$setLocale()
        },
        ...mapMutations('user', ['clear','setUserInfo']),
        ...mapMutations('process', ['setCollapsed','setFilter']),
        setLocale() {
            this.setLanguage(!this.english);
        },
        setWindowTitle(){
            window.title = preset.app?preset.app.title:"";
        }
    }
}
